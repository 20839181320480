<template>
  <div>
    <about-us />
    <northstar-rankings />
  </div>
</template>

<script lang="ts">
import AboutUs from "./AboutUs.vue";
import NorthstarRankings from "./NorthstarRankings.vue";
export default {
  components: {
    AboutUs,
    NorthstarRankings,
  },
};
</script>

<style></style>
