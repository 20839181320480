<template>
  <div>
    <h2>Recruitment</h2>
    <div>
      Open for every role and class - even if your experience sucks but you
      think you're good then please apply, on the extra lookout for these
      specific classes below
      <ul>
        <li>Melee flex</li>
        <li>DK</li>
        <li>Boomkin</li>
        <li>Mage</li>
      </ul>
    </div>

    <h2>History</h2>
    <p>
      Northstar has been around in a semi-hardcore manner since the burning
      crusade days with various realm rankings over the years. Entering legion a
      couple of old Northstar players and some others decided to tone it down a
      bit and not raid as often anymore. However, most people might relate
      casual raiding or three day raiding as non-progress aimed timewaste;
      instead, we're looking to push content as much as we did before with full
      focus at the times we raid while having a big banter doing it.
    </p>
    <p>
      We offer you an international raiding community with progress hungry
      people who enjoy the raiding phenomena just as much as you do with a
      “casual” yet focused approach of only four raid days a week with
      <b>NO SPLITS</b> on Wednesdays,Thursdays, Sundays and Mondays from
      20:00-23:00 ST. You will be able to see end bosses and progress in mythic
      modes without having to sacrifice too many other things, we hope this will
      attract some old hardcore players like some of us to join our ranks. We
      also tend to raid only 1 day a week on farm and that is on Sundays.
    </p>
    <p>
      In return we need you to be a experienced and motivated raider who knows
      your class in and out, who won't waste the little time we have with petty
      afks and silly whining. We recruit only the best players in order to make
      up for the loss of 2-3 raid days. We also want mature players who are able
      to handle progress bumps and constructive criticism without making raids
      unbearable for everybody else.
    </p>
    <div>
      If this sounds like something you would be interrested in, do not hesitate
      to add any officer on battletag:
      <ul>
        <li>Ariton (RL - Boosting) - Ari#2764</li>
        <li>spdne (Recruitment)</li>
        <li>Dent247 (Recruitment)</li>
        <li>Reminale (Recruitment)</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
