import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }
const _hoisted_2 = { class: "component-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_HomeStart = _resolveComponent("HomeStart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideBar, {
      class: "sidebar",
      currentPage: _ctx.setActive,
      onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPage($event)))
    }, null, 8, ["currentPage"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.setActive === 1)
        ? (_openBlock(), _createBlock(_component_HomeStart, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}