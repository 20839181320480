import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_about_us = _resolveComponent("about-us")!
  const _component_northstar_rankings = _resolveComponent("northstar-rankings")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_about_us),
    _createVNode(_component_northstar_rankings)
  ]))
}