<template>
  <div class="table-wrapper">
    <table class="kill-table">
      <tbody>
        <tr>
          <th>World</th>
          <th>Boss</th>
          <th>Realm</th>
          <th>Video</th>
          <th>Date</th>
        </tr>
        <tr>
          <td colspan="5" class="tier">Amirdrassil, the Dream's Hope</td>
        </tr>
        <tr>
          <td class="epic">85</td>
          <td class="boss">Fyrakk the Blazing</td>
          <td class="legendary">1</td>
          <td>
            <a
              href="https://www.youtube.com/watch?v=MzS42aBs68w"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../assets/youtube.svg" />
            </a>
          </td>
          <td>Jan 10, 2024</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Aberrus, the Shadowed Crucible</td>
        </tr>
        <tr>
          <td class="epic">100</td>
          <td class="boss">Scalecommander Sarkareth</td>
          <td class="legendary">1</td>
          <td>
            <a
              href="https://www.youtube.com/watch?v=nlYqsDemLsc"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../assets/youtube.svg" />
            </a>
          </td>
          <td>Jun 14, 2023</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Vault of the Incarnates</td>
        </tr>
        <tr>
          <td class="epic">96</td>
          <td class="boss">Raszageth</td>
          <td class="legendary">1</td>
          <td>
            <a
              href="https://www.youtube.com/watch?v=PRbeL9OnnHo"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../assets/youtube.svg" />
            </a>
          </td>
          <td>Feb 05, 2023</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Sepulcher of the First Ones</td>
        </tr>
        <tr>
          <td class="rare">141</td>
          <td class="boss">The Jailer</td>
          <td class="legendary">1</td>
          <td>
            <a
              href="https://www.youtube.com/watch?v=ZDYvgy7Zh24"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../assets/youtube.svg" />
            </a>
          </td>
          <td>June 01, 2022</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Sanctum of Domination</td>
        </tr>
        <tr>
          <td class="rare">263</td>
          <td class="boss">Sylvanas Windrunner</td>
          <td class="legendary">3</td>
          <td>
            <a
              href="https://www.youtube.com/watch?v=bam8d_ltM9M"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../assets/youtube.svg" />
            </a>
          </td>
          <td>Sep 07, 2021</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Castle Nathria</td>
        </tr>
        <tr>
          <td class="rare">283</td>
          <td class="boss">Sire Denathrius</td>
          <td class="legendary">3</td>
          <td>
            <a
              href="https://www.youtube.com/watch?v=Sb-2-m33G-Y"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../assets/youtube.svg" />
            </a>
          </td>
          <td>Mar 02, 2021</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Ny'Alotha the Waking City</td>
        </tr>
        <tr>
          <td class="uncommon">2134</td>
          <td class="boss">N'zoth the Corruptor</td>
          <td class="epic">52</td>
          <td><img src="../assets/youtube.svg" class="disabled" /></td>
          <td>Aug 30, 2020</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">The Eternal Palace</td>
        </tr>
        <tr>
          <td class="rare">490</td>
          <td class="boss">Queen Azshara</td>
          <td class="epic">15</td>
          <td>
            <a
              href="https://www.youtube.com/watch?v=m9INS59m158"
              target="_blank"
              rel="noreferrer"
            >
              <img src="../assets/youtube.svg" />
            </a>
          </td>
          <td>Nov 10, 2019</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Crucible of Storms</td>
        </tr>
        <tr>
          <td class="rare">966</td>
          <td class="boss">Uu'nat, Harbringer of the Void</td>
          <td class="epic">26</td>
          <td><img src="../assets/youtube.svg" class="disabled" /></td>
          <td>Apr 17, 2019</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Battle Of Dazar'alor</td>
        </tr>
        <tr>
          <td class="rare">927</td>
          <td class="boss">Lady Jaina Proudmoore</td>
          <td class="epic">31</td>
          <td><img src="../assets/youtube.svg" class="disabled" /></td>
          <td>May 20, 2019</td>
        </tr>
        <tr>
          <td colspan="5" class="tier">Uldir</td>
        </tr>
        <tr>
          <td class="uncommon">1303</td>
          <td class="boss">G'huun</td>
          <td class="epic">40</td>
          <td><img src="../assets/youtube.svg" class="disabled" /></td>
          <td>Jan 06, 2019</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@use "./northstarrankings.scss";
</style>
